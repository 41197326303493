<template>
    <b-modal :id="modalId" modal-class="startup-trackers-list-modal modal-md" title="Trackers" @hide="onCloseModal"
             hide-footer>
        <div class="main-table">
            <h5 class="heading5 heading5--secondary mb-3">{{heading}}</h5>
            <ul class="list--trackers">
                <li v-for="tracker in trackers" :key="tracker.id">
                    <a :href="'/trackers/' + tracker.id" target="_blank" :title="tracker.name">{{tracker.name}}</a>
                </li>
            </ul>
        </div>
    </b-modal>
</template>

<script>
    export default {
        name: 'StartupTrackersListModal',
        data() {
            return {
                modalId: 'startup-trackers-list-modal',
                startupName: '',
                trackers: [],
            }
        },
        computed: {
            heading() {
                return this.startupName + ' is in ' + this.trackers.length + ' trackers:'
            }
        },
        methods: {
            open(startup) {
                this.startupName = startup.name
                this.trackers = startup.trackers
                this.$bvModal.show(this.modalId)
            },
            onCloseModal() {
                this.startupName = ''
                this.trackers = []
            },
        }
    }
</script>
